var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _vm.projectDetails == null ? _c("Loader") : _vm._e(),
      _vm.projectDetails
        ? _c("div", { staticClass: "page-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "go-back-div",
                on: {
                  click: function ($event) {
                    return _vm.goBackToWorkSpace()
                  },
                },
              },
              [
                _c("img", { attrs: { src: "/img/onboarding/back-icon.svg" } }),
                _c("span", [_vm._v("Go Back")]),
              ]
            ),
            _vm._m(0),
            _c("div", { staticClass: "content-wrapper" }, [
              _c(
                "div",
                { staticClass: "sprint-process" },
                _vm._l(_vm.process, function (stage, i) {
                  return _c("div", { key: i, staticClass: "stage-box" }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticClass: "stage-icon" }, [
                        _c("img", { attrs: { src: _vm.imgLink + stage.img } }),
                      ]),
                      i < 5
                        ? _c("div", { staticClass: "arrow-connect" }, [
                            _c("img", {
                              attrs: { src: _vm.imgLink + "arrow.svg" },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "stage-name" }, [
                      _vm._v(_vm._s(stage.name)),
                    ]),
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "page-description" }, [
                _c("div", { staticClass: "left-div" }, [
                  _vm._m(1),
                  _vm._m(2),
                  _c("div", { staticClass: "action-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "grupa-blue-btn",
                        on: { click: _vm.openPaymentMethods },
                      },
                      [_vm._v("\n              Proceed to Pay\n            ")]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right-div" },
                  [
                    _c("div", { staticClass: "cost-text" }, [_vm._v("COST")]),
                    _c("div", { staticClass: "sprint-amount" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(
                            _vm.priceRoundUp(
                              _vm.projectDetails.design_thinking_fee
                            ).price
                          ) +
                          "\n            "
                      ),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "23px", color: "#000" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.priceRoundUp(
                                _vm.projectDetails.design_thinking_fee
                              ).decimal
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "pay-info" }, [
                      _vm._v(
                        "\n            Pay 50% ($" +
                          _vm._s(_vm.projectDetails.design_thinking_fee / 2) +
                          ") now, pay\n            the rest at the end of the sprint.\n          "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-text",
                        staticStyle: { "margin-top": "24px" },
                      },
                      [
                        _vm._v(
                          "\n            DELIVERABLES - What you get\n          "
                        ),
                      ]
                    ),
                    _vm._l(_vm.sprint_tasks, function (item, k) {
                      return _c("div", { key: k, staticClass: "item_list" }, [
                        _c("img", {
                          staticStyle: { "margin-right": "8px" },
                          attrs: { src: "/img/onboarding/bullet.svg" },
                        }),
                        _vm._v(
                          "\n            " + _vm._s(item) + "\n          "
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("div", { staticClass: "faq-wrapper" }, [_c("FaqSection")], 1),
            _vm._m(3),
          ])
        : _vm._e(),
      _vm.openStripe
        ? _c(
            "StripeModal",
            _vm._b(
              {
                attrs: { amount: _vm.projectDetails.design_thinking_fee / 2 },
                on: { close: _vm.closeModal },
              },
              "StripeModal",
              {
                user: _vm.user,
                modalType: _vm.modalType,
                paymentInfo: _vm.paymentInfo,
                userCreditCard: _vm.userCreditCard,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-head" }, [
      _c(
        "div",
        { staticClass: "page-title", staticStyle: { width: "410px" } },
        [
          _vm._v(
            "\n        Kickoff with Design Sprint & Product Roadmapping\n      "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "design-instruction" }, [
      _vm._v(
        "\n            The Design Sprint is a week-long collaborative process (originally\n            created by "
      ),
      _c("span", [_vm._v("the guys at Google Ventures")]),
      _vm._v(
        " ) that refines\n            the idea, and offer product solutions that cover user experience,\n            technical feasibility & business requirements.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sprint-duration" }, [
      _vm._v("DURATION: "),
      _c("span", [_vm._v("1 week")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-div" }, [
      _vm._v("\n      Have any unanswered questions?\n      "),
      _c("span", { staticStyle: { color: "#0781f2" } }, [
        _vm._v(" Have a chat with us"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }