<template>
  <div class="faq-conainer">
    <div class="faq-header">FREQUENTLY ASKED QUESTIONS</div>
    <div class="section-container">
      <div class="faq-box" v-for="(faq, index) in faqList" :key="index">
        <div class="question">
          {{ faq.question }}
        </div>
        <div class="answer">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      faqList: [
        {
          question: `How long is design sprint?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `Who is doing the documentation?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `Why am I paying before I can even speak to the team?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `What’s your refund policy?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `How much time is required from me?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `Why am I paying before I can even speak to the team?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `How much more will I spend to I get my product?`,
          answer: `Between his office hours and his patients, Polly saw but little of her father; for Dr. Adams was the popular physician of the large.`,
        },
        {
          question: `Why am I paying before I can even speak to the team?`,
          answer: `You pay to reserve the team and book them for a session that covers team evaluation, consultation and ideation.`,
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style scoped>
.faq-conainer {
  width: 1063px;
  padding-top: 48px;
}
.faq-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: initial;
}
.section-container {
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.faq-box {
  width: 410px;
  text-align: initial;
  margin-bottom: 48px;
}
.question {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.answer {
  margin-top: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
}
</style>

