var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq-conainer" }, [
    _c("div", { staticClass: "faq-header" }, [
      _vm._v("FREQUENTLY ASKED QUESTIONS"),
    ]),
    _c(
      "div",
      { staticClass: "section-container" },
      _vm._l(_vm.faqList, function (faq, index) {
        return _c("div", { key: index, staticClass: "faq-box" }, [
          _c("div", { staticClass: "question" }, [
            _vm._v("\n        " + _vm._s(faq.question) + "\n      "),
          ]),
          _c("div", { staticClass: "answer" }, [
            _vm._v("\n        " + _vm._s(faq.answer) + "\n      "),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }