<template>
  <div>
    <Navbar />
    <Loader v-if="projectDetails == null" />
    <div v-if="projectDetails" class="page-wrapper">
      <div @click="goBackToWorkSpace()" class="go-back-div">
        <img src="/img/onboarding/back-icon.svg" />
        <span>Go Back</span>
      </div>
      <div class="page-head">
        <div class="page-title" style="width: 410px">
          Kickoff with Design Sprint & Product Roadmapping
        </div>
      </div>
      <div class="content-wrapper">
        <div class="sprint-process">
          <div class="stage-box" v-for="(stage, i) in process" :key="i">
            <div style="display: flex">
              <div class="stage-icon">
                <img :src="imgLink + stage.img" />
              </div>
              <div v-if="i < 5" class="arrow-connect">
                <img :src="imgLink + 'arrow.svg'" />
              </div>
            </div>
            <div class="stage-name">{{ stage.name }}</div>
          </div>
        </div>
        <div class="page-description">
          <div class="left-div">
            <div class="design-instruction">
              The Design Sprint is a week-long collaborative process (originally
              created by <span>the guys at Google Ventures</span> ) that refines
              the idea, and offer product solutions that cover user experience,
              technical feasibility & business requirements.
            </div>
            <div class="sprint-duration">DURATION: <span>1 week</span></div>
            <div class="action-btn">
              <button class="grupa-blue-btn" @click="openPaymentMethods">
                Proceed to Pay
              </button>
            </div>
          </div>
          <div class="right-div">
            <div class="cost-text">COST</div>
            <div class="sprint-amount">
              ${{ priceRoundUp(projectDetails.design_thinking_fee).price }}
              <span style="font-size: 23px; color: #000">{{
                priceRoundUp(projectDetails.design_thinking_fee).decimal
              }}</span>
            </div>
            <div class="pay-info">
              Pay 50% (${{ projectDetails.design_thinking_fee / 2 }}) now, pay
              the rest at the end of the sprint.
            </div>
            <div class="cost-text" style="margin-top: 24px">
              DELIVERABLES - What you get
            </div>
            <div class="item_list" v-for="(item, k) in sprint_tasks" :key="k">
              <img src="/img/onboarding/bullet.svg" style="margin-right: 8px" />
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="faq-wrapper">
        <FaqSection />
      </div>
      <div class="footer-div">
        Have any unanswered questions?
        <span style="color: #0781f2"> Have a chat with us</span>
      </div>
    </div>
    <StripeModal
      v-if="openStripe"
      v-on:close="closeModal"
      v-bind:amount="projectDetails.design_thinking_fee / 2"
      v-bind="{ user, modalType, paymentInfo, userCreditCard }"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { fetchProject, clientPaymentCards } from "@/api";
import Navbar from "@/components/Navbar/navbar";
import FaqSection from "./faq_section";
import Loader from "@/components/general/centerLoader";
import StripeModal from "@/components/stripeModal";

export default {
  props: {},
  components: {
    Navbar,
    FaqSection,
    Loader,
    StripeModal,
  },
  data: () => ({
    openStripe: false,
    imgLink: "/img/designSprint/",
    process: [
      { name: "Understand the idea & problem", img: "stage1.svg" },
      { name: "Envision and ideate solutions", img: "stage2.svg" },
      { name: "Choose the best ideas", img: "stage3.svg" },
      { name: "Make a rough prototype", img: "stage4.svg" },
      { name: "Test, validate & learn what works", img: "stage5.svg" },
      { name: "Draw the Product Roadmap", img: "stage6.svg" },
    ],
    sprint_tasks: [
      "Well refined idea",
      "Basic product prototypes",
      "Feedback from user testing",
      "Product roadmap",
    ],
    userCreditCard: [],
    projectDetails: null,
    modalType: "design_sprint",
    paymentInfo: {
      purpose: "design sprint",
      payment_plan: "none",
      iteration_id: null,
    },
    projectTeam: {},
  }),
  created() {
    const id = this.$route.params.id;
    this.getClientProject(id);
    this.clientCreditCard();
  },
  methods: {
    ...mapActions("devDashboard", ["updateSelectedProject"]),
    getClientProject(id) {
      fetchProject(id)
        .then((res) => {
          this.$developConsole(res.data, "project details");
          this.projectDetails = res.data.project;
          // this.projectTeam = res.data.team_members[0];
          this.paymentInfo.iteration_id = res.data.documents.iterations[0].id;
          this.updateSelectedProject(res.data.project);
          this.$developConsole(res.data, "project details");
        })
        .catch((err) => {});
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.userCreditCard = response.data.cards;
          this.$developConsole(response.data.cards, "Api Response");
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
    priceRoundUp(data) {
      let price = data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: price.substring(0, price.length - 2),
        decimal: price.substring(price.length - 2, price.length),
      };
    },
    openPaymentMethods() {
      this.openStripe = true;
    },
    closeModal() {
      this.openStripe = false;
    },
    goBackToWorkSpace() {
      this.$router.push({ name: "project_workspace" });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    user() {
      return this.currentUser;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrapper {
  padding: 45px 10%;
  text-align: left;
}
.page-title {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: left;
  width: 410px;
  padding: 0px !important;
}
.page-head {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 61px;
}
.sprint-process {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px 60px;
  display: flex;
  width: 1063px;
}
.stage-name {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  width: 100px;
  margin-top: 8px;
  text-align: center;
}
.arrow-connect {
  width: 85.47px;
  position: relative;
}
.arrow-connect img {
  position: absolute;
  top: 50%;
}
.faq-wrapper,
.content-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 48px;
  flex-direction: column;
}
.faq-wrapper {
  border-top: 1px solid #e4e5e7;
}
.page-description {
  display: flex;
  margin-top: 48px;
  width: 1063px;
}
.left-div {
  margin-left: 100px;
}
.design-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 393px;
  text-align: left;
}
.design-instruction span {
  text-decoration: underline;
  color: #0781f2;
  cursor: pointer;
}
.right-div {
  text-align: left;
  margin-left: 100px;
}
.sprint-duration {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 24px;
}
.sprint-duration span {
  color: #1b1e22;
}
.action-btn {
  margin-top: 108px;
}
.cost-text {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
}
.sprint-amount {
  font-size: 46px;
  line-height: 100%;
  letter-spacing: -1px;
  color: #1b1e22;
  margin-top: 8px;
}
.pay-info {
  font-size: 14px;
  line-height: 130%;
  color: #1fa564;
  margin-top: 8px;
  width: 237px;
}
.item_list {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
}
.footer-div {
  margin-top: 24px;
  margin-bottom: 73px;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #e4e5e7;
  padding-top: 24px;
}
</style>
